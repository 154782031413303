<template>
  <div class="comparePage">
    <ProductInfo :detail="detail" />

    <div class="comparePage-body">
      <div class="txtitle">
        <img src="@/assets/img-t.png" style="width: 5px" />
        文件变化({{ allCount }})
      </div>
      <div class="subtitle">
        现版本对上版本共{{ allCount }}个文件夹发生变化，其中
        <span class="red">删除文件{{ detail?.removeNum || 0 }}个</span>
        <span class="blue">修改文件{{ detail?.modifyNum || 0 }}个</span>
        <span class="green">新增文件{{ detail?.addNum || 0 }}个</span>
      </div>

      <div class="comparebox">
        <div class="leftside">
          <TreeItem
            v-for="item in menuData"
            :key="item.name"
            :data="item"
            :openKeys="openKeys"
            @toggle="handleToggle"
            compareType="origin"
            @leafClick="leafClick"
          />
        </div>
        <div class="rightside">
          <TreeItem
            v-for="item in menuData"
            :key="item.name"
            :data="item"
            :openKeys="openKeys"
            @toggle="handleToggle"
            compareType="target"
            @leafClick="leafClick"
          />
        </div>
      </div>
    </div>

    <FileCompareModal v-model="compareVisible" :compareData="compareData" />
  </div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue';
import TreeItem, { type TreeItemType } from './components/TreeItem.vue';
import FileCompareModal from './components/FileCompareModal.vue';
import ProductInfo from './components/ProductInfo.vue';
import { getCompoareInfo, fetchCompareDetail } from '@/api/compare';
import has from 'lodash/has';

const route = useRoute();
const loading = ref(false);
const openKeys = ref<string[]>([]);
const compareVisible = ref(false);
const detail = ref<Record<string, any>>({});
const menuData = ref<Record<string, any>>([]);
const compareData = ref({
  to: '',
  from: '',
  filePath: '',
});

const allCount = computed(() => {
  return (detail.value.modifyNum || 0) + (detail.value.removeNum || 0) + (detail.value.addNum || 0);
});

function handleToggle(item: TreeItemType) {
  const xindex = openKeys.value.findIndex((x) => x == item.name);
  if (xindex >= 0) {
    openKeys.value.splice(xindex, 1);
  } else {
    openKeys.value.push(item.name);
  }
}

async function leafClick(item: TreeItemType) {
  // 只有modify 才要弹窗显示修改
  if (item.state !== 0) {
    return;
  }

  // png gif jpg jpeg zip aar 等格式不支持对比
  const whiteList = ['.gif', '.jpg', '.jpeg', '.zip', '.aar', '.tar'];

  if (whiteList.find((x) => item.name.toLocaleLowerCase().endsWith(x))) {
    message.warn('该文件不支持对比');
    return;
  }

  message.loading({ content: '加载中...', key: 'fetchCompareDetail' });
  loading.value = true;
  const res = await fetchCompareDetail({
    filePath: item.name,
    compareId: route.params.id,
  }).catch(() => null);
  loading.value = false;
  message.destroy();
  if (res?.code == '200') {
    compareData.value = res?.data || {};
    compareVisible.value = true;
  } else {
    message.warning(res?.msg || '数据获取失败');
  }
}

onMounted(() => {
  document.title = '文件比对';
  fetchData();
});

// 过滤出tree中包含有state字段的节点
function hasModify(treeItem: TreeItemType): boolean {
  if (has(treeItem, 'state')) {
    return true;
  }

  if (!treeItem?.children?.length) return false;

  return !!treeItem?.children.find((x) => hasModify(x));
}

// 给每个节点添加是否修改的参数
function addModifyFlag(treeData: TreeItemType[]) {
  treeData.forEach((item) => {
    item.hasModify = hasModify(item);

    if (item.children?.length) {
      addModifyFlag(item.children);
    }
  });
}

// 过滤出包含hasModify的节点
function menuFilter(treeData: TreeItemType[]) {
  return treeData.filter((x) => {
    if (!x.hasModify) {
      return false;
    }

    if (x.children?.length) {
      x.children = menuFilter(x.children);
    }

    return true;
  });
}

async function fetchData() {
  const res = await getCompoareInfo({ compareId: route.params.id });
  if (res?.code == '200') {
    detail.value = res?.data;
    try {
      const treeJson = JSON.parse(res?.data?.fileTreeJson);
      addModifyFlag(treeJson);
      menuData.value = menuFilter(treeJson);

      const filename = (res?.data?.compareZipUrl || '').split('/').pop();
      if (filename) {
        document.title = filename;
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    message.warning('数据获取失败');
  }
}
</script>
<style lang="less" scoped>
@import url(./index.less);

.red {
  color: rgba(235, 0, 0, 0.85);
}
.blue {
  color: rgba(19, 113, 255, 1);
}
.green {
  color: rgba(0, 194, 43, 1);
}
</style>
