<script setup lang="ts">
import QRCode from './QrCode.vue';
import { useOriginDataStore } from '@/stores/originDataStore';
import { buildResult } from '../hooks/useResult';
import ButtonGroup from './ButtonGroup.vue';

const emit = defineEmits<{
  (event: 'toggleStar'): void;
}>();

const { isMobile } = buildResult();
const store = useOriginDataStore();

const originData = computed(() => store.data);
const openModal = ref<boolean>(false);
const autoTestLevel = computed(() => store.data?.autoTestLevel);
const goPop = () => {
  openModal.value = true;
};

function toggleStar() {
  emit('toggleStar');
}
</script>

<template>
  <div class="outer">
    <img src="@/assets/banner-pc.png" alt="" class="banner" v-if="!isMobile" />
    <img src="@/assets/banner-mobile.png" alt="" class="banner-mobile" v-else />
    <div class="info-box">
      <div class="avatar-box">
        <div class="avatar-wrapper">
          <img :src="originData?.iconUrl" alt="" class="avatar" v-if="originData?.iconUrl" />
          <img src="@/assets/defualt.png" alt="" class="avatar" v-else />
          <img src="@/assets/formal.png" alt="" class="right-top" v-if="originData?.packageType == 1" />
          <img src="@/assets/sandbox.png" alt="" class="right-top" v-else />
        </div>
        <div class="detail-info">
          <div class="detail-info__top">
            {{ originData?.productName }}

            <img
              v-if="originData?.reqSource == 'intranet' && originData?.manualMark != 0"
              src="@/assets/star-y.png"
              style="width: 20px; margin-left: 12px; cursor: pointer"
              @click="toggleStar"
            />

            <img
              v-if="originData?.reqSource == 'intranet' && originData?.manualMark == 0"
              src="@/assets/star.png"
              style="width: 20px; margin-left: 12px; cursor: pointer"
              @click="toggleStar"
            />
          </div>
          <div class="detail-info__middle">
            <img src="@/assets/ios-icon.png" alt="" class="chln-icon" v-if="originData?.platform === 2" />
            <img src="@/assets/android-icon.png" alt="" class="chln-icon" v-if="originData?.platform === 1" />
            <img src="@/assets/tiktok.png" alt="" class="chln-icon" v-if="originData?.platform === 3 && originData?.channelMinigameValue === '抖音'" />
            <img src="@/assets/wechat.png" alt="" class="chln-icon" v-if="originData?.platform === 3 && originData?.channelMinigameValue === '微信'" />
            <!-- <img src="@/assets/formal_pkg.png" alt="" class="chln-icon" v-if="originData?.packageType == 1" />
            <img src="@/assets/test_pkg.png" alt="" class="chln-icon" v-else /> -->
            <div class="chln">{{ originData?.chnl }}</div>
            <div class="version chln" v-show="originData?.version">V{{ originData?.version }}</div>
            <div class="version chln" v-show="!isMobile && originData?.projectSize">{{ originData?.projectSize }}</div>
            <div class="version chln" v-show="!isMobile && originData?.createTime">打包时间：{{ originData?.createTime }}</div>
            <div class="version chln" v-show="!isMobile && originData?.engineVersion">{{ originData?.engineVersion }}</div>
            <div class="version chln" v-show="!isMobile && originData?.businessId">编号：{{ originData?.businessId }}</div>
          </div>
          <div class="version graytext" v-show="isMobile" style="margin-top: 3px; font-size: 12px !important">打包时间：{{ originData?.createTime }}</div>
          <div class="version graytext" v-show="isMobile" style="margin-top: 3px; font-size: 12px !important">编号：{{ originData?.businessId }}</div>
          <ButtonGroup v-show="!isMobile"></ButtonGroup>
        </div>
        <div class="badge-wrap">
          <div v-show="isMobile" @click="goPop" class="badge-wrap">
            <img src="@/assets/level1-mobile.png" alt="" class="badge-icon" v-show="autoTestLevel == 1" />
            <img src="@/assets/level2-mobile.png" alt="" class="badge-icon" v-show="autoTestLevel == 2" />
            <img src="@/assets/level3-mobile.png" alt="" class="badge-icon" v-show="autoTestLevel == 3" />
          </div>
        </div>
      </div>
      <QRCode class="code" v-if="!isMobile && originData && originData.platform != 3"></QRCode>
    </div>
    <a-modal v-model:open="openModal" @ok="openModal = false" :footer="null">
      <div class="inner-modal">
        <div v-show="autoTestLevel == 1">
          <img src="@/assets/level1.png" alt="" />
          <div class="txt">青铜级</div>
        </div>
        <div v-show="autoTestLevel == 2">
          <img src="@/assets/level2.png" alt="" />
          <div class="txt">黄金级</div>
        </div>
        <div v-show="autoTestLevel == 3">
          <img src="@/assets/level1.png" alt="" />
          <div class="txt">王者级</div>
        </div>
        <img src="@/assets/divider.png" alt="" class="divider" />
        <div class="desc-box">
          <div class="single-item">
            <div class="icon"></div>
            青铜：正常安装且启动正常
          </div>
          <div class="single-item">
            <div class="icon"></div>
            黄金：通用脚本6小时压力测试正常
          </div>
          <div class="single-item">
            <div class="icon"></div>
            王者：专用脚本6小时压力测试正常
          </div>
        </div>
        <a-button type="primary" @click="openModal = false" class="btn">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="less">
@import url(./basicinfo.less);
</style>
<style scoped lang="less">
@media (max-width: 550px) {
  .mobile-viewport div.outer {
    .banner-mobile {
      width: 100vw;
      height: 100%;
      min-height: 165px;
    }
    .info-box {
      width: calc(100vw - 20px);
      height: 109px;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      transform: translateY(-45px);
      left: 10px;
      .avatar-box {
        padding: 8px;
        justify-content: space-between;
        width: 100%;
        .avatar-wrapper {
          position: relative;
          width: 73px;
          height: 73px;
          .right-top {
            position: absolute;
            right: -4px;
            top: -3px;
            width: 40px;
            height: 40px;
          }
          .avatar {
            width: 73px;
            height: 73px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }

        .detail-info {
          margin-left: 10px;
          &__top {
            font-size: 20px;
            overflow-x: hidden;
            max-width: calc(100vw - 200px);
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .chln-icon {
            width: 20px;
            height: 20px;
          }
          .chln {
            margin-left: 5px;
            white-space: nowrap; /* 确保文本不换行 */
            overflow: hidden; /* 隐藏溢出的文本 */
            text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
            max-width: 130px;
          }
        }
      }
    }
  }
}
.inner-modal {
  text-align: center;
  .txt {
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    font-weight: bold;
  }
  .divider {
    margin: 10px 0;
  }
  .desc-box {
    margin: 0 auto;
    width: 100%;
    height: 98px;
    border-radius: 4px;
    background: rgba(235, 242, 255, 1);
    padding: 14px;
    box-sizing: border-box;
    font-size: 14px;
    .single-item {
      color: rgba(0, 0, 0, 0.85);
      font-family: PingFang SC;
      font-size: 14px;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      .icon {
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: rgba(60, 83, 255, 1);
        margin-right: 10px;
        margin-top: 7px;
      }
    }
  }
  .btn {
    width: 120px;
    margin-top: 19px;
    height: 36px;
    background: rgba(19, 113, 255, 1);
  }
}
</style>
