<template>
  <a-modal
    class="fileCompareCom"
    width="100%"
    :title="`文件比对`"
    centered
    :bodyStyle="{ height: '80vh', overflow: 'hidden auto', position: 'relative' }"
    :open="Boolean(modelValue)"
    :maskClosable="false"
    okText="关闭"
    :cancelButtonProps="{ disabled: true }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="imgcompare" v-if="isPng">
      <div class="sidebox">
        <img :src="compareData.from" v-if="compareData.from" />
      </div>
      <div class="sidebox">
        <img :src="compareData.to" v-if="compareData.to" />
      </div>
    </div>
    <div ref="boxRef" v-show="!isPng"></div>
    <div></div>
  </a-modal>
</template>
<script lang="ts" setup>
import { withDefaults, defineProps, defineEmits } from 'vue';
import { Diff2HtmlUI, Diff2HtmlUIConfig } from 'diff2html/lib/ui/js/diff2html-ui-slim.js';
import { createPatch } from 'diff';
// Requires `yarn add highlight.js`
import 'highlight.js/styles/github.css';
import 'diff2html/bundles/css/diff2html.min.css';
type Props = {
  modelValue: boolean | number | string;
  compareData: Record<string, any>;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void;
}>();

const boxRef = ref();
const isPng = computed(() => {
  return props.compareData.filePath && props.compareData.filePath.toLocaleLowerCase().endsWith('.png');
});
watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      nextTick(() => {
        if (!isPng.value) {
          initView();
        }
      });
    }
  },
);

const isMobile = computed(() => {
  const ua = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
});

function initView() {
  const diffString = createPatch(props.compareData.filePath, props.compareData.from, props.compareData.to);
  const configuration: Diff2HtmlUIConfig = {
    drawFileList: true,
    fileListToggle: false,
    fileListStartVisible: false,
    fileContentToggle: false,
    matching: 'lines',
    outputFormat: isMobile.value ? 'line-by-line' : 'side-by-side',
    synchronisedScroll: true,
    highlight: true,
    renderNothingWhenEmpty: false,
  };
  const diff2htmlUi = new Diff2HtmlUI(boxRef.value, diffString, configuration);
  diff2htmlUi.draw();
  diff2htmlUi.highlightCode();
}

function handleOk() {
  emit('update:modelValue', false);
}
function handleCancel() {
  emit('update:modelValue', false);
}
</script>

<style lang="less">
.fileCompareCom {
  .ant-modal-footer .ant-btn-default {
    display: none;
  }

  .imgcompare {
    width: 100%;
    height: 100%;
    display: flex;
    .sidebox {
      width: 50%;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media (max-width: 550px) {
  div.fileCompareCom {
    .ant-modal-content {
      padding: 10px !important;
    }

    .imgcompare {
      flex-direction: column;
      .sidebox {
        width: 100%;
        height: 50%;
      }
    }
  }
}
</style>
