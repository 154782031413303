<script setup lang="ts">
import { defineProps, withDefaults } from 'vue';
import { DownloadOutlined } from '@ant-design/icons-vue';

const props = withDefaults(
  defineProps<{
    detail: Record<string, any>;
  }>(),
  {},
);

const isMobile = computed(() => {
  const ua = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
});

function download() {
  if (props.detail?.compareZipUrl) {
    window.location.href = props.detail?.compareZipUrl;
  }
}
</script>

<template>
  <div class="outer">
    <img src="@/assets/banner-pc.png" alt="" class="banner" v-if="!isMobile" />
    <img src="@/assets/banner-mobile.png" alt="" class="banner-mobile" v-else />
    <div class="info-box">
      <div class="avatar-box">
        <div class="avatar-wrapper">
          <img :src="detail?.iconUrl" alt="" class="avatar" v-if="detail?.iconUrl" />
          <img src="@/assets/defualt.png" alt="" class="avatar" v-else />
          <img src="@/assets/formal.png" alt="" class="right-top" v-if="detail?.packageType == 1" />
          <img src="@/assets/sandbox.png" alt="" class="right-top" v-else />
        </div>
        <div class="detail-info">
          <div class="xbox">
            <div class="detail-info__top">{{ detail?.productName }}</div>
            <div class="detail-info__middle">
              <img src="@/assets/ios-icon.png" alt="" class="chln-icon" v-if="detail?.platform === 2" />
              <img src="@/assets/android-icon.png" alt="" class="chln-icon" v-if="detail?.platform === 1" />
              <img src="@/assets/tiktok.png" alt="" class="chln-icon" v-if="detail?.platform === 3 && detail?.channelMinigameValue === '抖音'" />
              <img src="@/assets/wechat.png" alt="" class="chln-icon" v-if="detail?.platform === 3 && detail?.channelMinigameValue === '微信'" />
              <!-- <img src="@/assets/formal_pkg.png" alt="" class="chln-icon" v-if="detail?.packageType == 1" />
              <img src="@/assets/test_pkg.png" alt="" class="chln-icon" v-else /> -->
              <div class="chln">{{ detail?.chnl }}</div>
              <div class="version" v-show="detail?.projectSize">{{ detail?.projectSize }}</div>
              <div class="version" v-show="detail?.engineVersion">{{ detail?.engineVersion }}</div>
            </div>
          </div>
          <div class="detail-info__middle" style="margin-top: 10px; margin-bottom: 10px">
            <div class="version" style="color: rgba(0, 0, 0, 0.45)">
              <span>上版本号：V{{ detail?.fromVersion }}</span>
              <span style="margin-left: 10px">上版本编号：{{ detail?.fromBusinessId }}</span>
            </div>
          </div>
          <div class="detail-info__middle" style="margin-bottom: 20px">
            <div class="version">
              <span>现版本号：V{{ detail?.toVersion }}</span>
              <span style="margin-left: 10px">现版本编号：{{ detail?.toBusinessId }}</span>
            </div>
          </div>

          <div class="detail-info__middle downbox">
            <a-button type="primary" size="large" @click="download">
              <template #icon>
                <DownloadOutlined />
              </template>
              下载差异文件
            </a-button>
            <div class="version">文件生成时间：{{ detail?.updateTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.outer {
  position: relative;
  .banner {
    width: 100%;
  }
  .info-box {
    width: 1200px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(28px);
    margin: -120px auto 0;
    display: flex;
    justify-content: space-between;
    .avatar-box {
      display: flex;
      justify-content: flex-start;
      padding: 24px;
      box-sizing: border-box;
      .avatar-wrapper {
        width: 104px;
        height: 104px;
        position: relative;
        .right-top {
          position: absolute;
          right: -4px;
          top: -3px;
          width: 50px;
          height: 50px;
        }
        .avatar {
          width: 104px;
          height: 104px;
          border-radius: 10px;
        }
      }

      .detail-info {
        margin-left: 24px;
        &__top {
          color: rgba(0, 0, 0, 1);
          font-weight: bold;
          font-size: 22px;
        }
        .graytext {
          color: rgba(0, 0, 0, 0.65);
          font-family: AlibabaPuHuiTi2.0-55Regular;
          font-size: 12px;
        }
        &__middle {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 6px;
          .chln-icon {
            width: 22px;
            height: 22px;
            margin-right: 6px;
          }
          .chln {
            height: 19px;
            line-height: 19px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            color: rgba(0, 84, 254, 1);
            font-family: AlibabaPuHuiTi2.0-55Regular;
            font-size: 12px;
            margin-left: 10px;
            margin-top: 2px;
            padding: 0 5px;
          }
          .version {
            margin-top: 2px;
            margin-left: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .code {
      width: 124px !important;
      height: 124px !important;
      margin: 14px 24px;
    }
  }
}
</style>
<style scoped lang="less">
@media (max-width: 550px) {
  div.outer {
    .banner-mobile {
      width: 100%;
    }
    .info-box {
      width: calc(100vw - 20px);
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.8);

      .avatar-box {
        padding: 16px 4px 4px;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        position: relative;

        .detail-info {
          margin-left: 0;
          width: 100%;

          .xbox {
            position: absolute;
            top: 16px;
            left: 120px;
            .detail-info__middle {
              flex-wrap: wrap;
              gap: 6px;
            }
          }

          .downbox {
            flex-direction: column;
            align-items: flex-start;

            .version {
              margin-top: 10px;
            }
          }

          .version {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
